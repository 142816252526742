import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import IMAGES from '../images/Images';

export default function UnknownErrorFallback() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        gap: 2,
      }}
    >
      <Typography variant="h3" gutterBottom>
        Something went wrong
      </Typography>

      <Box
        component="img"
        src={IMAGES.errorImage}
        alt="Error Image"
        sx={{ width: '500px' }}
      />

      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
        <Button variant="contained" onClick={handleGoBack}>
          Go Back
        </Button>
      </Box>
    </Box>
  );
}
