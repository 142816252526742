import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { colorThemes } from '../theme';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import HorizontalLinearStepper from '../sign_up/HorizontalLinearStepper';
import ExternalContextStage from './ExternalContextStage';
import InternalContextStage from './InternalContextStage';
import AIObjectivesStage from './AIObjectivesStage';
import ScopeStage from './ScopeStage';
import { scrollbarSx } from '../components/StyledScrollable';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { OrganizationQuery } from '../__generated__/gql/graphql';
import { ToastContainer, toast } from 'react-toastify';

const GET_QUESTIONNAIRE = gql`
  query Organization {
    organization {
      id
      profileQuestionnaire {
        country
        associatedPolicies
        otherPolicies
        valueNorms {
          organizationValue
          description
        }
        externalContext
        externalParties {
          name
          requirements
        }
        internalContext
        contractualObligations
        internalParties {
          name
          requirements
        }
        isClimateRelevant
        objectives {
          objectiveName
          description
          measure
        }
        areObjectsMonitored
        areObjectsConsistent
        areObjectsCommunicated
        areObjectsApplicable
        principles {
          principleName
          description
          measure
        }
        systemScope
        systemResources
        risks
        prohibitedSystems
        opportunities
        considerInternalExternal
        considerRequirements
        reviewPeriod
      }
    }
  }
`;

const UPDATE_ORGANIZATION_PROFILE = gql`
  mutation UpdateOrganizationProfile(
    $profileQuestionnaire: ProfileQuestionnaireInput
  ) {
    updateOrganizationProfile(profileQuestionnaire: $profileQuestionnaire) {
      organization {
        name
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Dictionary = Record<string, any>;
function removeTypenameKey(arrayOfDictionaries: Dictionary[]): Dictionary[] {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return arrayOfDictionaries.map(({ __typename, ...rest }) => rest);
}

export default function OrganizationProfileQuestionnaire() {
  const { data } = useSuspenseQuery<OrganizationQuery>(GET_QUESTIONNAIRE);

  const questionnaire = data?.organization?.profileQuestionnaire;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formSchema: any = {
    country: questionnaire?.country || '',
    associatedPolicies: questionnaire?.associatedPolicies || [],
    otherPolicies: questionnaire?.otherPolicies || '',
    valueNorms: questionnaire?.valueNorms || [
      { organizationValue: '', description: '' },
    ],
    externalContext: questionnaire?.externalContext || '',
    externalParties: questionnaire?.externalParties || [
      { name: '', requirements: '' },
    ],
    internalContext: questionnaire?.internalContext || '',
    contractualObligations: questionnaire?.contractualObligations || '',
    internalParties: questionnaire?.internalParties || [
      { name: '', requirements: '' },
    ],
    isClimateRelevant: questionnaire?.isClimateRelevant || false,
    objectives: questionnaire?.objectives || [
      { objectiveName: '', description: '', measure: '' },
    ],
    areObjectsMonitored: questionnaire?.areObjectsMonitored || false,
    areObjectsConsistent: questionnaire?.areObjectsConsistent || false,
    areObjectsCommunicated: questionnaire?.areObjectsCommunicated || false,
    areObjectsApplicable: questionnaire?.areObjectsApplicable || false,
    principles: questionnaire?.principles || [
      { principleName: '', description: '', measure: '' },
    ],
    systemScope: questionnaire?.systemScope || '',
    systemResources: questionnaire?.systemResources || '',
    risks: questionnaire?.risks || [],
    prohibitedSystems: questionnaire?.prohibitedSystems || '',
    opportunities: questionnaire?.opportunities || [],
    considerInternalExternal: questionnaire?.considerInternalExternal || false,
    considerRequirements: questionnaire?.considerRequirements || false,
    reviewPeriod: questionnaire?.reviewPeriod || 3,
  };
  const methods = useForm({ defaultValues: formSchema });

  const [updateOrganizationProfile] = useMutation(UPDATE_ORGANIZATION_PROFILE, {
    onCompleted: () => {
      toast.success('Settings saved');
    },
    onError: error => {
      toast.error(`Error saving settings: ${error.message}`);
    },
  }); // TODO: OnSuccess Dialog

  const maxStages = 3;
  const [stage, setStage] = useState<number>(0);
  const stages: JSX.Element[] = [
    <ExternalContextStage />,
    <InternalContextStage />,
    <AIObjectivesStage />,
    <ScopeStage />,
  ];

  const stageLabels: string[] = [
    'External Context',
    'Internal Context',
    'Objectives & Principles',
    'Scope',
  ];

  const buttonStyle = {
    backgroundColor: colorThemes.ACTION_BLUE,
    maxWidth: '40px',
    height: '40px',
  };

  function PrevButton() {
    if (stage === 0) {
      return <></>;
    }
    return (
      <IconButton
        id="prev-button"
        onClick={() => setStage(stage - 1)}
        sx={buttonStyle}
      >
        <NavigateBefore />
      </IconButton>
    );
  }

  function NextButton() {
    if (stage === maxStages) {
      return <></>;
    }
    return (
      <IconButton
        id="next-button"
        onClick={() => setStage(stage + 1)}
        sx={buttonStyle}
      >
        <NavigateNext />
      </IconButton>
    );
  }

  function SaveButton() {
    const getValidEnumValue = (value: string) => (value === '' ? null : value);
    function handleSave() {
      updateOrganizationProfile({
        variables: {
          profileQuestionnaire: {
            country: getValidEnumValue(methods.getValues('country')),
            associatedPolicies: getValidEnumValue(
              methods.getValues('associatedPolicies')
            ),
            otherPolicies: methods.getValues('otherPolicies'),
            valueNorms: removeTypenameKey(methods.getValues('valueNorms')),
            externalContext: methods.getValues('externalContext'),
            externalParties: removeTypenameKey(
              methods.getValues('externalParties')
            ),
            internalContext: methods.getValues('internalContext'),
            contractualObligations: methods.getValues('contractualObligations'),
            internalParties: removeTypenameKey(
              methods.getValues('internalParties')
            ),
            isClimateRelevant: methods.getValues('isClimateRelevant'),
            objectives: removeTypenameKey(
              methods.getValues('objectives')
            ).filter(dict => dict.objectiveName !== ''),
            principles: removeTypenameKey(
              methods.getValues('principles')
            ).filter(dict => dict.principleName !== ''),
            areObjectsApplicable: methods.getValues('areObjectsApplicable'),
            areObjectsCommunicated: methods.getValues('areObjectsCommunicated'),
            areObjectsConsistent: methods.getValues('areObjectsConsistent'),
            areObjectsMonitored: methods.getValues('areObjectsMonitored'),
            systemResources: methods.getValues('systemResources'),
            riskProfile: methods.getValues('riskProfile'),
            prohibitedSystems: methods.getValues('prohibitedSystems'),
            systemScope: methods.getValues('systemScope'),
            considerInternalExternal: methods.getValues(
              'considerInternalExternal'
            ),
            risks: methods.getValues('risks'),
            opportunities: methods.getValues('opportunities'),
            considerRequirements: methods.getValues('considerRequirements'),
            reviewPeriod: methods.getValues('reviewPeriod'),
          },
        },
      });
    }
    return (
      <Button variant="contained" onClick={handleSave}>
        Save
      </Button>
    );
  }

  return (
    <>
      <Box
        sx={{
          height: '96%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '80%',
            height: '80%',
            backgroundColor: colorThemes.DARK_BLUE_600,
            borderRadius: '2rem',
            padding: '40px',
          }}
        >
          <FormProvider {...methods}>
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                alignItems: 'stretch',
                overflowY: 'auto',
              }}
            >
              <Grid
                item
                xs={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <PrevButton />
              </Grid>
              <Grid item xs={10}>
                <HorizontalLinearStepper
                  step={stage}
                  stepLabels={stageLabels}
                />
                <Box
                  sx={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    paddingX: '2rem',
                    maxHeight: '100%',
                    ...scrollbarSx,
                  }}
                >
                  {stages[stage]}
                </Box>
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <NextButton />
              </Grid>
            </Grid>
          </FormProvider>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'start',
          height: '5%',
          gap: '1rem',
        }}
      >
        <Box sx={{ width: '150px' }}>
          <Controller
            name="reviewPeriod"
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <InputLabel>Review Period</InputLabel>
                <Select
                  labelId="simple-select-period"
                  label="Review Period"
                  value={value}
                  onChange={onChange}
                  size="small"
                >
                  {ReviewPeriodOptions.map(option => (
                    <MenuItem key={option} value={option}>
                      {option} Months
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            control={methods.control}
            rules={{ required: true }}
          />
        </Box>
        <SaveButton />
      </Box>
      <ToastContainer />
    </>
  );
}

const ReviewPeriodOptions = [3, 6, 9, 12];
