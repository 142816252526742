import { Suspense, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';
import { Xwrapper } from 'react-xarrows';
import { Navigate, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import TreeVisualizer from './tree_visualization/TreeVisualizer';
import ProjectView from './project_view/ProjectView';
import NavigationSidebar from './navigation_sidebar/NavigationSidebar';
import Dashboard from './dashboard/Dashboard';
import TableView from './table_view/TableView';
import { Loading } from './components/Loading';
import ApplicationPage from './components/ApplicationPage';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import OAuthRedirectPage from './oauth/OAuthRedirectPage';
import { ProjectContextProvider } from './contexts/project';
import RiskAssessmentLoader from './risk_management/risk_assessment/RiskAssessmentLoader';
import RiskTreatment from './risk_management/risk_treatment/RiskTreatment';
import RiskManagement from './risk_management/RiskManagement';
import ProjectWrapper from './components/ProjectWrapper';
import OrganizationProfileQuestionnaire from './organization_profile_questionnaire/OrganizationProfileQuestionnaire';
import UserManagementPage from './user_management/UserManagementPage';
import { ProfilePage } from './profile/ProfilePage';
import { useAuth } from 'react-oidc-context';
import posthog from 'posthog-js';
import RiskClassification from './risk_classification/RiskClassification';
import GovernancePage from './governance_framework_view/GovernancePage';
import { UnsavedChangesProvider } from './components/unsaved_changes/UnsavedChangesProvider';
import { OrganizationSettingsPage } from './organization_settings/OrganizationSettingsPage';
import ProjectGovernancePage from './project_governance_framework_view/ProjectGovernancePage';
import GovernanceOverviewPage from './governance_framework_view/GovernanceOverviewPage';
import WelcomePage from './welcome/WelcomePage';
import theme from './theme';
import EnabledFeaturesLoader from './EnabledFeatures';
import DocumentationsPage from './documentations/DocumentationsPage';
import DocumentationPage from './documentations/documentation_view/DocumentationPage';

function App() {
  const auth = useAuth();
  useEffect(() => {
    if (auth.user) {
      posthog.identify(auth.user.profile.sub, {
        email: auth.user.profile.email,
        company_id: auth.user.profile.company_id,
      });
    }
  }, [auth.user]);

  return (
    <ThemeProvider theme={theme}>
      <DndProvider backend={HTML5Backend}>
        <ProjectContextProvider>
          <CssBaseline />
          <Box sx={{ display: 'flex', height: '100%' }} flex={1}>
            <Suspense fallback={<Loading message="loading" />}>
              <Xwrapper>
                <RecoilRoot>
                  <EnabledFeaturesLoader />
                  <Routes>
                    <Route path="/" element={<Navigate to="/projects" />} />
                    <Route path="/welcome" element={<WelcomePage />} />
                    <Route element={<NavigationSidebar />}>
                      <Route
                        path="/trail_graph/:projectId?"
                        element={
                          <ApplicationPage hideOverflow={true}>
                            <ProjectWrapper>
                              <TreeVisualizer />
                            </ProjectWrapper>
                          </ApplicationPage>
                        }
                      />
                      <Route
                        path="/table_view/:projectId?"
                        element={
                          <ApplicationPage>
                            <TableView />
                          </ApplicationPage>
                        }
                      />
                      <Route
                        path="/project_governance/:projectId?/:frameworkId?"
                        element={
                          <ApplicationPage>
                            <ProjectGovernancePage />
                          </ApplicationPage>
                        }
                      />
                      <Route
                        path="/risk_assessment/:projectId?/:riskAssessmentName?/:assessmentFrameworkId?"
                        element={
                          <ApplicationPage>
                            <ProjectWrapper>
                              <UnsavedChangesProvider>
                                <RiskAssessmentLoader />
                              </UnsavedChangesProvider>
                            </ProjectWrapper>
                          </ApplicationPage>
                        }
                      />
                      <Route
                        path="/risk_treatment/:projectId?/:riskAssessmentName?/:assessmentFrameworkId?"
                        element={
                          <ApplicationPage>
                            <ProjectWrapper>
                              <UnsavedChangesProvider>
                                <RiskTreatment />
                              </UnsavedChangesProvider>
                            </ProjectWrapper>
                          </ApplicationPage>
                        }
                      />
                      <Route
                        path="/projects/:projectId?"
                        element={
                          <ApplicationPage>
                            <ProjectView />
                          </ApplicationPage>
                        }
                      />
                      <Route
                        path="/profile"
                        element={
                          <ApplicationPage>
                            <ProfilePage />
                          </ApplicationPage>
                        }
                      />
                      <Route
                        path="/governance_overview"
                        element={
                          <ApplicationPage>
                            <GovernanceOverviewPage />
                          </ApplicationPage>
                        }
                      />
                      <Route
                        path="/framework/:frameworkId"
                        element={
                          <ApplicationPage>
                            <GovernancePage />
                          </ApplicationPage>
                        }
                      />
                      <Route
                        path="/oauth_redirect"
                        element={
                          <ApplicationPage>
                            <OAuthRedirectPage />
                          </ApplicationPage>
                        }
                      />
                      <Route
                        path="/dashboard/:projectId?"
                        element={
                          <ApplicationPage>
                            <ProjectWrapper>
                              <Dashboard />
                            </ProjectWrapper>
                          </ApplicationPage>
                        }
                      />
                      <Route
                        path="/risk_management/:projectId?"
                        element={
                          <ApplicationPage>
                            <ProjectWrapper>
                              <RiskManagement />
                            </ProjectWrapper>
                          </ApplicationPage>
                        }
                      />
                      <Route
                        path="/users"
                        element={
                          <ApplicationPage>
                            <UnsavedChangesProvider>
                              <UserManagementPage />
                            </UnsavedChangesProvider>
                          </ApplicationPage>
                        }
                      />
                      <Route
                        path="/questionnaire"
                        element={
                          <ApplicationPage>
                            <OrganizationProfileQuestionnaire />
                          </ApplicationPage>
                        }
                      />
                      <Route
                        path="/risk_classification/:projectId?"
                        element={
                          <ApplicationPage>
                            <ProjectWrapper>
                              <RiskClassification />
                            </ProjectWrapper>
                          </ApplicationPage>
                        }
                      />
                      <Route
                        path="/organization_settings"
                        element={
                          <ApplicationPage>
                            <OrganizationSettingsPage />
                          </ApplicationPage>
                        }
                      />
                      {/* <Route
                        path="/sign_up"
                        element={
                          <ApplicationPage>
                            <SignUp />
                          </ApplicationPage>
                        }
                      /> */}
                      <Route
                        path="/documentations"
                        element={
                          <ApplicationPage>
                            <DocumentationsPage />
                          </ApplicationPage>
                        }
                      />
                      <Route
                        path="/documentation/:documentationId?"
                        element={
                          <ApplicationPage>
                            <UnsavedChangesProvider>
                              <DocumentationPage />
                            </UnsavedChangesProvider>
                          </ApplicationPage>
                        }
                      />
                    </Route>
                  </Routes>
                </RecoilRoot>
              </Xwrapper>
            </Suspense>
          </Box>
        </ProjectContextProvider>
      </DndProvider>
    </ThemeProvider>
  );
}

export default App;
