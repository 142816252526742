import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Autocomplete,
  TextField,
  CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import UserTag from './UserTag';
import { User } from '../__generated__/gql/graphql';
import { useLazyQuery, gql } from '@apollo/client';

const USERS_BASIC_INFO = gql`
  query GetAvailableUsers {
    allUsers {
      id
      name
      email
      __typename
    }
  }
`;

type EditableUserTagProps = {
  user?: User;
  size?: 'small' | 'medium';
  variant?: 'avatar' | 'chip' | 'full' | 'text';
  onUserChange: (newUser: User | null) => void;
};

const EditableUserTag: React.FC<EditableUserTagProps> = ({
  user,
  size = 'medium',
  variant = 'avatar',
  onUserChange,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [fetchUsers, { loading, error, data }] = useLazyQuery(USERS_BASIC_INFO);

  const handleEditClick = () => {
    setIsEditing(true);
    fetchUsers();
  };

  const handleUserChange = (
    event: React.SyntheticEvent,
    value: User | null
  ) => {
    onUserChange(value);
    setIsEditing(false);
  };

  return (
    <Box
      sx={{
        width: '200px',
        height: '32px',
        alignItems: 'center',
        position: 'relative',
        '&:hover .edit-icon': { opacity: 1 },
      }}
    >
      {!isEditing && <UserTag user={user} size={size} variant={variant} />}
      {!isEditing && (
        <IconButton
          size="small"
          onClick={handleEditClick}
          className="edit-icon"
          sx={{
            opacity: 0,
            transition: 'opacity 0.2s',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      )}
      {isEditing && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <Autocomplete
            options={data?.allUsers || []}
            loading={loading}
            getOptionLabel={(option: User) => option.name || option.email}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Search user"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            onChange={handleUserChange}
            sx={{
              '& .MuiInputBase-root': {
                fontSize: 'inherit',
                padding: 0,
              },
              '& .MuiInputBase-input': {
                padding: 0,
                height: '100%',
              },
            }}
          />
        </Box>
      )}
      {error && <div>Error loading users</div>}
    </Box>
  );
};

export default EditableUserTag;
