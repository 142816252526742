import React from 'react';
import { Typography, Stack, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import UserTag from './UserTag';
import EditableUserTag from './EditableUserTag';
import { User } from '../__generated__/gql/graphql';
import { colorThemes } from '../theme';

type UserListProps = {
  users: (User | null)[];
  emptyMessage?: string;
  remediationMessage?: string;
  variant?: 'avatar' | 'chip' | 'full' | 'text';
  size?: 'small' | 'medium';
  editable?: boolean;
  onUserChange?: (oldUser: User | null, newUser: User | null) => void;
};

const UserList: React.FC<UserListProps> = ({
  users,
  emptyMessage = 'Not assigned',
  remediationMessage,
  variant = 'avatar',
  size = 'medium',
  editable = false,
  onUserChange,
}) => {
  const isSmall = size === 'small';
  const typographyVariant = isSmall ? 'body2' : 'body1';

  const handleUserChange = (oldUser: User | null) => (newUser: User | null) => {
    if (onUserChange) {
      onUserChange(oldUser, newUser);
    }
  };

  const renderHelpIcon = () =>
    remediationMessage && (
      <Tooltip title={remediationMessage}>
        <HelpOutlineIcon
          sx={{
            fontSize: isSmall ? '0.875rem' : '1rem',
            color: colorThemes.DARK_BLUE_700,
            marginLeft: '4px',
          }}
        />
      </Tooltip>
    );

  return (
    <Stack direction="row" spacing={1} flexWrap="wrap" alignItems="center">
      {users.length > 0 ? (
        users.map((user, index) => (
          <div
            key={user?.id || `empty-${index}`}
            style={{ marginBottom: '8px' }}
          >
            {editable ? (
              <EditableUserTag
                user={user}
                variant={variant}
                size={size}
                onUserChange={handleUserChange(user)}
              />
            ) : (
              <UserTag user={user} variant={variant} size={size} />
            )}
          </div>
        ))
      ) : (
        <div
          style={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}
        >
          {editable ? (
            <EditableUserTag
              user={undefined}
              variant={variant}
              size={size}
              onUserChange={handleUserChange(null)}
            />
          ) : (
            <Typography variant={typographyVariant} component="div">
              {emptyMessage}
            </Typography>
          )}
          {renderHelpIcon()}
        </div>
      )}
    </Stack>
  );
};

export default UserList;
