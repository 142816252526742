import { Clear, Description, Download } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Divider,
  Typography,
  Tooltip,
  Link,
} from '@mui/material';
import { FC, useState } from 'react';
import { GovernanceEvidence } from '../__generated__/gql/graphql';

interface EvidenceCardProps {
  evidence: GovernanceEvidence;
  handleEvidenceDelete: (id: string) => void;
}

const EvidenceCard: FC<EvidenceCardProps> = ({
  evidence,
  handleEvidenceDelete,
}) => {
  const [open, setOpen] = useState(false);

  const handleToggleOpen = () => {
    if (evidence?.description || evidence?.file) {
      setOpen(!open);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: '#295581',
        color: 'white',
        p: 2,
        borderRadius: 2,
        boxShadow: 3,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '100%',
        cursor: evidence?.description || evidence?.file ? 'pointer' : 'default',
        transition: 'background-color 0.3s',
        '&:hover': {
          bgcolor: '#3a6ea5',
        },
      }}
      onClick={handleToggleOpen}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Description />
          <Typography variant="subtitle1" sx={{ ml: 1, fontWeight: 600 }}>
            {evidence.title}
          </Typography>
        </Box>
        <Tooltip title="Delete Evidence">
          <IconButton
            size="small"
            sx={{ color: 'white' }}
            onClick={e => {
              e.stopPropagation();
              handleEvidenceDelete(evidence.id ?? '');
            }}
          >
            <Clear fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      {open && (
        <Box sx={{ width: '100%', overflow: 'hidden' }}>
          <Divider sx={{ width: '100%', my: 1, borderColor: 'white' }} />
          {evidence.description && (
            <Typography
              variant="body2"
              sx={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
            >
              {evidence.description}
            </Typography>
          )}
          {evidence.file && (
            <Link
              component="a"
              href={evidence.file.url ?? ''}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
              color="inherit"
              sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
              onClick={e => e.stopPropagation()}
            >
              <Download sx={{ mr: 1 }} />
              <Typography variant="body2" noWrap>
                Download File
              </Typography>
            </Link>
          )}
        </Box>
      )}
    </Box>
  );
};

export default EvidenceCard;
