import '@toast-ui/editor/dist/toastui-editor.css';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, ButtonGroup, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { GetVersionedFileQuery } from '../../../../__generated__/gql/graphql';
import {
  editableFileIdAtom,
  majorVersionAtom,
} from '../../DocumentationPageStates';
import VersionSelector from '../../../../components/VersionSelector';
import { LoadingButton } from '@mui/lab';
import { colorThemes } from '../../../../theme';
import { toast } from 'react-toastify';
import { Editor } from '@toast-ui/react-editor';
import { Loading } from '../../../../components/Loading';

const GET_VERSIONED_FILE = gql(`
    query GetVersionedFile($versionedFileId: String!, $versionNumber: Int!) {
      versionedFile(versionedFileId: $versionedFileId) {
        id
        lastVersion
        content(versionNumber: $versionNumber)
        url(versionNumber: $versionNumber)
      }
    }
  `);

const UPDATE_VERSIONED_FILE = gql(`
    mutation UpdateVersionedS3File($versionedFileId: String!, $versionNumber: Int!, $editableFileContent: String!) {
    updateVersionedS3File(
      versionedFileId: $versionedFileId
      versionNumber: $versionNumber
      editableFileContent: $editableFileContent
    ) {
      versionedFile {
        id
      }
    }
  }
    `);

const CREATE_NEW_VERSIONED_FILE = gql(`
    mutation CreateNewVersionedS3File($versionedFileId: String!, $editableFileContent: String!) {
    createVersionedS3File(
      versionedFileId: $versionedFileId
      editableFileContent: $editableFileContent
    ) {
      versionedFile {
        id
        lastVersion
      }
    }
  }
    `);

export default function EditorTab() {
  const editorRef = useRef<Editor>(null);

  const [minorVersions, setMinorVersions] = useState<number[]>([]);
  const [minorVersion, setMinorVersion] = useState<number>(0);
  const majorVersion = useRecoilValue(majorVersionAtom);
  const versionedFileId = useRecoilValue(editableFileIdAtom);

  const { loading, refetch, data } = useQuery<GetVersionedFileQuery>(
    GET_VERSIONED_FILE,
    {
      variables: {
        versionedFileId,
        versionNumber: 0,
      },
      onCompleted: data => {
        editorRef.current
          ?.getInstance()
          .setMarkdown(data?.versionedFile?.content || '');

        setMinorVersions(
          Array.from(
            { length: (data?.versionedFile?.lastVersion || 0) + 1 },
            (_, i) => i
          )
        );
      },
      skip: !versionedFileId,
    }
  );
  const handleVersionChange = async (newVersion: number) => {
    setMinorVersion(newVersion);
    const { data } = await refetch({
      versionedFileId,
      versionNumber: newVersion,
    });

    if (data?.versionedFile?.content) {
      editorRef.current?.getInstance().setMarkdown(data.versionedFile.content);
    }
  };
  const [createNewVersionedS3File, { loading: createLoading }] = useMutation(
    CREATE_NEW_VERSIONED_FILE,
    {
      onCompleted: data => {
        setMinorVersions(
          Array.from(
            { length: data?.versionedFile?.lastVersion || 1 },
            (_, i) => i
          )
        );
      },
    }
  );

  const handleCreateVersionedS3File = async () => {
    const content = editorRef.current?.getInstance().getMarkdown();
    try {
      await createNewVersionedS3File({
        variables: {
          versionedFileId,
          editableFileContent: content,
        },
      });
      toast.success('File created successfully');

      const { data } = await refetch({
        versionedFileId,
        versionNumber: minorVersion,
      });

      if (data?.versionedFile?.lastVersion !== undefined) {
        setMinorVersions(
          Array.from(
            { length: data.versionedFile.lastVersion + 1 },
            (_, i) => i
          )
        );
      }
    } catch (e) {
      toast.error('Error creating file');
      console.error('Error creating file:', e);
    }
  };

  const [updateVersionedS3File, { loading: updateLoading }] = useMutation(
    UPDATE_VERSIONED_FILE
  );
  const handleSave = async () => {
    const content = editorRef.current?.getInstance().getMarkdown();
    try {
      updateVersionedS3File({
        variables: {
          versionedFileId,
          versionNumber: minorVersion,
          editableFileContent: content,
        },
      });
      toast.success('File updated successfully');
    } catch (error) {
      toast.error('Error updating file');
      console.error('Error updating file:', error);
    }
  };

  return (
    <Box sx={{ width: 'full' }}>
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          gap: '0.5rem',
          justifyContent: 'space-between',
          paddingBottom: '1rem',
        }}
      >
        <Typography variant="h6">Edit</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Typography variant="subtitle1">Version:</Typography>
          <VersionSelector
            majorVersion={majorVersion ?? 0}
            elements={minorVersions}
            selectedElement={minorVersion}
            setSelectedElement={handleVersionChange}
          />
          <ButtonGroup>
            <LoadingButton
              variant="contained"
              onClick={handleSave}
              loading={updateLoading}
              sx={{
                '&:hover': {
                  backgroundColor: colorThemes.YELLOW_200,
                },
                '&:disabled': {
                  backgroundColor: colorThemes.YELLOW_600,
                },
              }}
            >
              Save
            </LoadingButton>
            <LoadingButton
              variant="contained"
              onClick={handleCreateVersionedS3File}
              loading={createLoading}
              sx={{
                '&:hover': {
                  backgroundColor: colorThemes.YELLOW_200,
                },
                '&:disabled': {
                  backgroundColor: colorThemes.YELLOW_600,
                },
              }}
            >
              Save As New Version
            </LoadingButton>
          </ButtonGroup>
        </Box>
      </Box>
      {loading ? (
        <Loading message="loading" />
      ) : data ? (
        <Editor
          ref={editorRef}
          initialValue=""
          height="650px"
          previewStyle="vertical"
          initialEditType="wysiwyg"
          useCommandShortcut={true}
        />
      ) : (
        <Typography variant="h6">
          No documentation generated yet. Generate Documentation to get started.
        </Typography>
      )}
    </Box>
  );
}
