import React, { useState } from 'react';
import { useMutation, gql, useApolloClient, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  IconButton,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Description, Link as LinkIcon, Search } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import {
  AvailableGovernanceEvidencesQuery,
  AvailableGovernanceEvidencesQueryVariables,
} from '../__generated__/gql/graphql';

const CREATE_EVIDENCE = gql`
  mutation CreateEvidence(
    $title: String!
    $description: String
    $fileName: String
    $fileContent: String
  ) {
    createEvidence(
      title: $title
      description: $description
      fileName: $fileName
      fileContent: $fileContent
    ) {
      evidence {
        id
        title
        description
        file {
          url
        }
      }
    }
  }
`;

const AVAILABLE_GOVERNANCE_EVIDENCES = gql`
  query AvailableGovernanceEvidences {
    availableGovernanceEvidences {
      id
      title
      description
      file {
        url
      }
    }
  }
`;

const ADD_EVIDENCE_TO_REQUIREMENT = gql`
  mutation AddEvidenceToRequirement($requirementId: ID!, $evidenceId: ID!) {
    addEvidenceToRequirement(
      requirementId: $requirementId
      evidenceId: $evidenceId
    ) {
      requirement {
        id
        evidence {
          id
          title
          description
        }
      }
    }
  }
`;

type EvidenceModalProps = {
  open: boolean;
  onClose: () => void;
  requirementStatusId: string;
};

function EvidenceModal({
  open,
  onClose,
  requirementStatusId,
}: EvidenceModalProps) {
  const client = useApolloClient();
  const [activeTab, setActiveTab] = useState(0);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEvidence, setSelectedEvidence] = useState<string | null>(null);

  const [createEvidence] = useMutation(CREATE_EVIDENCE, {
    update(cache, { data: { createEvidence } }) {
      const newEvidence = createEvidence.evidence;

      cache.modify({
        id: cache.identify({
          __typename: 'GovernanceRequirementStatus',
          id: requirementStatusId,
        }),
        fields: {
          userStatus(existingUserStatus = {}) {
            const updatedEvidence = existingUserStatus.evidence
              ? [...existingUserStatus.evidence, newEvidence]
              : [newEvidence];

            return {
              ...existingUserStatus,
              evidence: updatedEvidence,
            };
          },
        },
      });
    },
  });

  const [addEvidenceToRequirement] = useMutation(ADD_EVIDENCE_TO_REQUIREMENT);

  const [isLoading, setIsLoading] = React.useState(false);

  const handleEvidenceSubmit = async () => {
    setIsLoading(true);
    try {
      let fileContent = null;
      let fileName = null;

      if (file) {
        const reader = new FileReader();
        fileContent = await new Promise(resolve => {
          reader.onload = event =>
            resolve(btoa(event.target?.result as string));
          reader.readAsBinaryString(file);
        });
        fileName = file.name;
      }

      const result = await createEvidence({
        variables: {
          title,
          description,
          fileName,
          fileContent,
        },
      });

      const evidenceId = result.data.createEvidence.evidence.id;

      await addEvidenceToRequirement({
        variables: {
          requirementId: requirementStatusId,
          evidenceId,
        },
      });

      setTitle('');
      setDescription('');
      setFile(null);
      onClose();
      toast.success('Evidence added successfully');
    } catch (error) {
      toast.error('Failed to add evidence');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLinkEvidence = async () => {
    if (selectedEvidence) {
      setIsLoading(true);
      try {
        const result = await addEvidenceToRequirement({
          variables: {
            requirementId: requirementStatusId,
            evidenceId: selectedEvidence,
          },
        });

        client.cache.modify({
          id: client.cache.identify({
            __typename: 'GovernanceRequirementStatus',
            id: requirementStatusId,
          }),
          fields: {
            userStatus: (existingUserStatus = {}) => ({
              ...existingUserStatus,
              evidence: [
                ...(existingUserStatus.evidence || []),
                result.data.addEvidenceToRequirement.requirement.evidence[0],
              ],
            }),
          },
        });

        toast.success('Evidence linked successfully');
        onClose();
      } catch (error) {
        toast.error('Failed to link evidence');
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      let result;
      if (activeTab === 0) {
        result = await handleEvidenceSubmit();
      } else if (activeTab === 1 && selectedEvidence) {
        result = await handleLinkEvidence();
      }

      if (result) {
        onClose();
        toast.success('Evidence added successfully');
      }
    } catch (error) {
      toast.error('Failed to add evidence');
    } finally {
      setIsLoading(false);
    }
  };

  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const { data: availableEvidences, loading: loadingEvidences } = useQuery<
    AvailableGovernanceEvidencesQuery,
    AvailableGovernanceEvidencesQueryVariables
  >(AVAILABLE_GOVERNANCE_EVIDENCES);

  const filteredEvidences =
    availableEvidences?.availableGovernanceEvidences.filter(
      evidence =>
        evidence.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        evidence.description?.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          height: '80vh',
          maxHeight: 600,
          width: '100%',
          maxWidth: 800,
        },
      }}
    >
      <DialogTitle>Add Evidence to Requirement</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Add new evidence or link existing evidence to this requirement.
        </Typography>
        <form onSubmit={handleSubmit}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="evidence tabs"
            sx={{ mb: 2 }}
          >
            <Tab icon={<Description />} label="New Evidence" />
            <Tab icon={<LinkIcon />} label="Link Evidence" />
          </Tabs>
          {activeTab === 0 && (
            <>
              <TextField
                fullWidth
                label="Title"
                value={title}
                onChange={e => setTitle(e.target.value)}
                required
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Description"
                value={description}
                onChange={e => setDescription(e.target.value)}
                multiline
                rows={4}
                sx={{ mb: 2 }}
              />
              <Box
                {...getRootProps()}
                sx={{
                  border: '2px dashed #cccccc',
                  borderRadius: '4px',
                  p: 4,
                  textAlign: 'center',
                  cursor: 'pointer',
                  mb: 2,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <Typography>Drop the file here ...</Typography>
                ) : (
                  <Typography>
                    {file
                      ? `File selected: ${file.name}`
                      : 'Drag and drop a file here, or click to select a file (optional)'}
                  </Typography>
                )}
              </Box>
            </>
          )}
          {activeTab === 1 && (
            <>
              <TextField
                fullWidth
                label="Search Existing Evidence"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: <Search />,
                }}
                sx={{ mb: 2 }}
              />
              <List sx={{ maxHeight: 300, overflow: 'auto', mb: 2 }}>
                {loadingEvidences ? (
                  <Typography>Loading evidences...</Typography>
                ) : (
                  filteredEvidences.map(evidence => (
                    <ListItem
                      key={evidence.id}
                      disablePadding
                      secondaryAction={
                        <IconButton edge="end" aria-label="preview">
                          {/* Add preview functionality here */}
                        </IconButton>
                      }
                    >
                      <ListItemButton
                        selected={selectedEvidence === evidence.id}
                        onClick={() => setSelectedEvidence(evidence.id)}
                      >
                        <ListItemText
                          primary={evidence.title}
                          secondary={evidence.description}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))
                )}
              </List>
            </>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          onClick={handleEvidenceSubmit}
          loading={isLoading}
          variant="contained"
        >
          Add Evidence
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default EvidenceModal;
