import { atom, useResetRecoilState } from 'recoil';
import {
  DocumentationTemplate,
  DocumentationTemplates,
} from './tabs/template/DocumentationTemplates';
import {
  DataSourceEnum,
  DocumentComponentTypeEnum,
} from '../../__generated__/gql/graphql';

export interface DocumentationEntry {
  id: string;
  type: DocumentComponentTypeEnum;
  name?: string;
  query?: string;
  args?: (string | null)[];
  dataSource?: DataSourceEnum;
}

export const documentTitleAtom = atom<string>({
  key: 'documentTitleAtom',
  default: 'New Document',
});

export const documentIdAtom = atom<string>({
  key: 'documentIdAtom',
  default: '',
});

export const documentComponentsAtom = atom<DocumentationEntry[]>({
  key: 'documentComponents',
  default: [],
});

export const majorVersionAtom = atom<number>({
  key: 'majorVersion',
  default: 1,
});

export const editableFileIdAtom = atom<string>({
  key: 'editableFileId',
  default: '',
});

export const documentTemplateAtom = atom<DocumentationTemplate | null>({
  key: 'documentTemplate',
  default: DocumentationTemplates[0],
});

export const useResetDocumentState = () => {
  const resetDocumentId = useResetRecoilState(documentIdAtom);
  const resetDocumentComponents = useResetRecoilState(documentComponentsAtom);
  const resetMajorVersion = useResetRecoilState(majorVersionAtom);
  const resetEditableFileId = useResetRecoilState(editableFileIdAtom);
  const resetDocumentTemplate = useResetRecoilState(documentTemplateAtom);
  const resetDocumentTitle = useResetRecoilState(documentTitleAtom);

  const resetAll = () => {
    resetDocumentTitle();
    resetDocumentId();
    resetDocumentComponents();
    resetMajorVersion();
    resetEditableFileId();
    resetDocumentTemplate();
  };

  return resetAll;
};
