import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Link,
} from '@mui/material';
import { gql, useSuspenseQuery } from '@apollo/client';

import { GetActivatedGovernanceFrameworksQuery } from '../__generated__/gql/graphql';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

const GET_GOVERNANCE_FRAMEWORKS = gql`
  query getActivatedGovernanceFrameworks {
    organization {
      governanceFrameworks {
        id
        description
        name
      }
    }
  }
`;

export default function GovernanceOverviewPage() {
  const { data } = useSuspenseQuery<GetActivatedGovernanceFrameworksQuery>(
    GET_GOVERNANCE_FRAMEWORKS
  );
  const navigate = useNavigate();

  const handleSelect = (name: string, frameworkId: string) => {
    navigate(`/framework/${frameworkId}/`);
  };

  const governanceFrameworks = data?.organization?.governanceFrameworks ?? [];

  return (
    <>
      <Box display={'flex'} gap={2} flexDirection={'column'} width={'100%'}>
        <Typography variant="h4" marginBottom={3}>
          Governance Frameworks
        </Typography>
        <Box width={'100%'} display={'flex'} justifyContent={'center'}>
          <Box
            sx={{
              width: '90%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography variant="h4">
              {governanceFrameworks.length === 0 ? (
                <>
                  No Frameworks -{' '}
                  <Link component={RouterLink} to="/organization_settings">
                    Go to the Organization Settings
                  </Link>{' '}
                  to enable a framework
                </>
              ) : governanceFrameworks.length > 1 ? (
                governanceFrameworks.length + ' Frameworks'
              ) : (
                '1 Framework'
              )}
            </Typography>
            <Box display="flex" flexWrap="wrap" gap="12px">
              {governanceFrameworks.map(framework => (
                <Card
                  key={framework!.id}
                  sx={{
                    width: 400,
                    height: 200,
                  }}
                >
                  <CardActionArea
                    sx={{ height: '100%', width: '100%' }}
                    onClick={() => handleSelect(framework!.name, framework!.id)}
                  >
                    <CardContent
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <Typography variant="h6">{framework!.name}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {framework!.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
