import React, { useState } from 'react';
import {
  useMutation,
  gql,
  useApolloClient,
  useSuspenseQuery,
} from '@apollo/client';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Drawer,
  DrawerProps,
  List,
  ListItem,
  Stack,
  Typography,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { colorThemes } from '../theme';
import AddIcon from '@mui/icons-material/Add';
import {
  RequirementCompletionStatusEnum,
  MaterializedRequirement,
  User,
  GetGovernanceRequirementStatusQuery,
} from '../__generated__/gql/graphql';
import RequirementProgressStepper from './RequirementProgressStepper';
import EvidenceCard from './EvidenceCard';
import { DateTime } from 'luxon';
import UserList from '../components/UserList';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EvidenceModal from './EvidenceModal';

const UPDATE_GOVERNANCE_REQUIREMENT_STATUS = gql`
  mutation UpdateGovernanceRequirementStatus(
    $materializedRequirementId: String!
    $markedAsCompleted: Boolean
    $completionStatus: RequirementCompletionStatusEnum
  ) {
    updateGovernanceRequirementStatus(
      materializedRequirementId: $materializedRequirementId
      markedAsCompleted: $markedAsCompleted
      completionStatus: $completionStatus
    ) {
      governanceRequirementStatus {
        materializedRequirementId
        markedAsCompleted
        completionStatus
      }
    }
  }
`;

const UNLINK_EVIDENCE_FROM_REQUIREMENT = gql`
  mutation UnlinkEvidenceFromRequirement(
    $requirementId: ID!
    $evidenceId: ID!
  ) {
    unlinkEvidenceFromRequirement(
      requirementId: $requirementId
      evidenceId: $evidenceId
    ) {
      requirement {
        id
        evidence {
          id
          title
          description
        }
      }
    }
  }
`;

const UPDATE_GOVERNANCE_REQUIREMENT_USERS = gql`
  mutation UpdateGovernanceRequirementUsers(
    $materializedRequirementId: String!
    $manualOverrideOwners: [String!]
    $manualOverrideApprovers: [String!]
  ) {
    updateGovernanceRequirementStatus(
      materializedRequirementId: $materializedRequirementId
      manualOverrideOwners: $manualOverrideOwners
      manualOverrideApprovers: $manualOverrideApprovers
    ) {
      governanceRequirementStatus {
        materializedRequirementId
        manualOverrideOwners {
          id
          name
          email
        }
        manualOverrideApprovers {
          id
          name
          email
        }
      }
    }
  }
`;

const GET_REQUIREMENT_STATUS = gql`
  query GetGovernanceRequirementStatus($materializedRequirementId: String!) {
    governanceRequirementStatus(
      materializedRequirementId: $materializedRequirementId
    ) {
      id
      completionStatus
      lastModified
      created
      evidence {
        id
        title
        description
        file {
          url
        }
      }
    }
  }
`;

const formatDateTime = (isoString: string) => {
  return DateTime.fromISO(isoString).toLocaleString(DateTime.DATETIME_MED);
};

export type SectionDrawerProps = DrawerProps & {
  requirement: MaterializedRequirement;
};

const cardProps = {
  padding: '12px',
  bgcolor: colorThemes.DARK_BLUE_800,
  borderRadius: '8px',
};

const headingProps = {
  component: 'h3' as const,
  fontSize: '20px',
  fontWeight: 600,
};

export function RequirementDrawer({
  requirement: {
    id,
    title,
    description,
    guidance,
    specifications,
    approvers,
    owners,
    evidenceSuggestions,
  },
  ...props
}: SectionDrawerProps) {
  const client = useApolloClient();
  const [updateStatus] = useMutation(UPDATE_GOVERNANCE_REQUIREMENT_STATUS);
  const [unlinkEvidenceFromRequirement] = useMutation(
    UNLINK_EVIDENCE_FROM_REQUIREMENT
  );
  const [updateUsers] = useMutation(UPDATE_GOVERNANCE_REQUIREMENT_USERS);

  const { data } = useSuspenseQuery<GetGovernanceRequirementStatusQuery>(
    GET_REQUIREMENT_STATUS,
    {
      variables: { materializedRequirementId: id },
    }
  );

  const [isEvidenceModalOpen, setIsEvidenceModalOpen] = useState(false);

  const openEvidenceModal = () => {
    setIsEvidenceModalOpen(true);
  };

  const closeEvidenceModal = () => {
    setIsEvidenceModalOpen(false);
  };

  const userStatus = data.governanceRequirementStatus;
  const evidences = userStatus?.evidence ?? [];
  const completionStatus =
    userStatus?.completionStatus ?? RequirementCompletionStatusEnum.NotStarted;

  const getButtonText = () => {
    switch (completionStatus) {
      case RequirementCompletionStatusEnum.NotStarted:
        return 'Mark as Assigned';
      case RequirementCompletionStatusEnum.Assigned:
        return 'Mark as Completed';
      case RequirementCompletionStatusEnum.Completed:
        return 'Mark as Approved';
      case RequirementCompletionStatusEnum.Approved:
        return 'Approved';
      default:
        return 'Update Status';
    }
  };

  const getNextStatus = () => {
    switch (completionStatus) {
      case RequirementCompletionStatusEnum.NotStarted:
        return RequirementCompletionStatusEnum.Assigned;
      case RequirementCompletionStatusEnum.Assigned:
        return RequirementCompletionStatusEnum.Completed;
      case RequirementCompletionStatusEnum.Completed:
        return RequirementCompletionStatusEnum.Approved;
      default:
        return completionStatus;
    }
  };

  const handleUpdateStatus = async (
    newStatus: RequirementCompletionStatusEnum
  ) => {
    try {
      const result = await updateStatus({
        variables: {
          materializedRequirementId: id,
          completionStatus: newStatus,
          markedAsCompleted:
            newStatus === RequirementCompletionStatusEnum.Completed,
        },
      });

      const updatedStatus =
        result.data.updateGovernanceRequirementStatus
          .governanceRequirementStatus;

      client.cache.modify({
        id: client.cache.identify({
          __typename: 'MaterializedRequirement',
          id,
        }),
        fields: {
          userStatus: existingStatus => ({
            ...existingStatus,
            completionStatus: updatedStatus.completionStatus,
          }),
        },
      });

      toast.success('Status update successful');
    } catch (error) {
      toast.error(
        'Failed to update status. Ensure you are listed as an owner or approver on the requirement.'
      );
      console.error(error);
    }
  };

  const handleUnlinkEvidence = async (evidenceId: string) => {
    try {
      await unlinkEvidenceFromRequirement({
        variables: { requirementId: userStatus?.id ?? '', evidenceId },
      });
      toast.success('Evidence unlinked successfully');
      client.cache.modify({
        id: client.cache.identify({
          __typename: 'MaterializedRequirement',
          id,
        }),
        fields: {
          userEvidence: (existingEvidence, { readField }) => ({
            ...existingEvidence,
            evidenceTexts: existingEvidence.evidenceTexts.filter(
              (evidence: any) => readField('id', evidence) !== evidenceId
            ),
          }),
        },
      });
    } catch (error) {
      toast.error('Failed to unlink evidence');
      console.error(error);
    }
  };

  const handleUserChange = async (
    userType: 'owner' | 'approver',
    oldUser: User | null,
    newUser: User | null
  ) => {
    try {
      const result = await updateUsers({
        variables: {
          materializedRequirementId: id,
          manualOverrideOwners:
            userType === 'owner' ? [newUser].map(user => user?.id) : undefined,
          manualOverrideApprovers:
            userType === 'approver'
              ? [newUser].map(user => user?.id)
              : undefined,
        },
      });

      const updatedStatus =
        result.data.updateGovernanceRequirementStatus
          .governanceRequirementStatus;

      client.cache.modify({
        id: client.cache.identify({
          __typename: 'MaterializedRequirement',
          id,
        }),
        fields: {
          owners: () => updatedStatus.manualOverrideOwners,
          approvers: () => updatedStatus.manualOverrideApprovers,
        },
      });

      toast.success(
        `${userType === 'owner' ? 'Owners' : 'Approvers'} updated successfully`
      );
    } catch (error) {
      toast.error(
        `Failed to update ${userType === 'owner' ? 'owners' : 'approvers'}`
      );
      console.error(error);
    }
  };

  return (
    <Drawer anchor="right" PaperProps={{ sx: { width: '66%' } }} {...props}>
      <Box padding="24px" color={colorThemes.GREY_100}>
        <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
          <Typography component="h2" fontSize="26px" fontWeight={700}>
            {title}
          </Typography>
          <ButtonGroup>
            <Button
              variant="outlined"
              onClick={() => handleUpdateStatus(getNextStatus())}
              disabled={
                completionStatus === RequirementCompletionStatusEnum.Approved
              }
            >
              {getButtonText()}
            </Button>
          </ButtonGroup>
        </Box>
        <Box
          sx={{
            marginTop: 3,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Stack direction="row" spacing={2}>
              <Typography>
                <strong>Owner:</strong>{' '}
              </Typography>
              <UserList
                users={owners as User[]}
                variant="chip"
                size="small"
                editable={true}
                onUserChange={(oldUser, newUser) =>
                  handleUserChange('owner', oldUser, newUser)
                }
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography>
                <strong>Approver:</strong>{' '}
              </Typography>
              <UserList
                users={approvers as User[]}
                variant="chip"
                size="small"
                editable={true}
                onUserChange={(oldUser, newUser) =>
                  handleUserChange('approver', oldUser, newUser)
                }
              />
            </Stack>
          </Box>
          <Box>
            <Typography>
              <strong>Created:</strong>{' '}
              {userStatus?.created ? formatDateTime(userStatus.created) : ''}
            </Typography>
            <Typography>
              <strong>Last edited:</strong>{' '}
              {userStatus?.lastModified
                ? formatDateTime(userStatus.lastModified)
                : ''}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ marginY: '12px', bgcolor: '#295581' }} />
        <Box display="flex" flexDirection="column" gap="16px">
          <Box display="grid" gridTemplateColumns="4fr 6fr" gap="16px">
            <Box {...cardProps} display="flex" flexDirection="column">
              <Typography {...headingProps}>Status</Typography>
              <RequirementProgressStepper status={completionStatus} />
            </Box>
            <Box {...cardProps}>
              <Typography {...headingProps} marginBottom="16px">
                Description
              </Typography>
              <Typography>{description}</Typography>
            </Box>
          </Box>
          <Box {...cardProps}>
            <Typography {...headingProps} marginBottom="16px">
              Guidance
            </Typography>
            <Typography>{guidance}</Typography>
          </Box>
          <Box display="grid" gridTemplateColumns="6fr 4fr" gap="20px">
            <Box
              {...cardProps}
              flex={1}
              display="flex"
              flexDirection="column"
              gap="16px"
            >
              <Box sx={{ '& > *': { mb: 2 } }}>
                <Typography {...headingProps}>Evidence</Typography>
                <Box display="flex" flexDirection={'column'} gap={2}>
                  {evidences.map((evidence, index) => (
                    <EvidenceCard
                      key={index}
                      evidence={evidence!}
                      handleEvidenceDelete={() =>
                        handleUnlinkEvidence(evidence?.id ?? '')
                      }
                    />
                  ))}
                </Box>
                {evidences.length === 0 && (
                  <Typography color="text.secondary">
                    No evidence added yet.
                  </Typography>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={openEvidenceModal}
                  >
                    Add Evidence
                  </Button>
                </Box>
                <Divider sx={{ my: 3, bgcolor: colorThemes.GREY_400 }} />
                {evidenceSuggestions && evidenceSuggestions.length > 0 && (
                  <Box>
                    <Typography variant="h6" fontWeight="bold" mb={2}>
                      Suggested Evidence:
                    </Typography>
                    <List>
                      {evidenceSuggestions.map((suggestion, index) => (
                        <ListItem key={index} sx={{ py: 0.5 }}>
                          <ListItemIcon sx={{ minWidth: '24px' }}>
                            <ArrowRightIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText
                            primary={suggestion?.description}
                            primaryTypographyProps={{ fontSize: '0.9rem' }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                )}
              </Box>
            </Box>
            <Box {...cardProps} flex={1}>
              <Typography {...headingProps}>Specs</Typography>
              <List>
                {specifications?.map(spec => (
                  <ListItem
                    key={spec?.description}
                    sx={{ display: 'flex', gap: '4px' }}
                  >
                    {spec?.description}
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </Box>
        <EvidenceModal
          open={isEvidenceModalOpen}
          onClose={closeEvidenceModal}
          requirementStatusId={userStatus?.id ?? ''}
        />
      </Box>
    </Drawer>
  );
}
