import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { v4 as uuidv4 } from 'uuid';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useState } from 'react';
import { Reorder } from 'framer-motion';
import { RemoveCircle } from '@mui/icons-material';
import {
  DocumentationEntry,
  documentComponentsAtom,
} from '../../DocumentationPageStates';
import { DocumentComponentTypeEnum } from '../../../../__generated__/gql/graphql';
import { colorThemes } from '../../../../theme';
import { DocumentComponentNameMap } from '../template/DocumentationTemplates';

export default function ComponentsTab() {
  const [currentComponents, setDocumentComponents] = useRecoilState(
    documentComponentsAtom
  );

  const predefinedComponents: DocumentationEntry[] = Object.values(
    DocumentComponentTypeEnum
  )
    .filter(enumValue => !currentComponents.find(c => c.type === enumValue))
    .map(enumValue => ({
      id: uuidv4(),
      name: enumValue,
      query: '',
      dataSource: undefined,
      type: enumValue,
    }));

  const [searchTerm, setSearchTerm] = useState<string>('');
  return (
    <Box sx={{ width: 'full' }}>
      <Grid container spacing={10}>
        <Grid item xs={5}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '0.5rem',
            }}
          >
            <Typography variant="h6">Components</Typography>
            <TextField
              id="search"
              sx={{ width: 200, height: 40 }}
              size="small"
              onChange={e => setSearchTerm(e.target.value)}
              placeholder="Search component"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              maxHeight: '650px',
              overflowY: 'auto',
            }}
          >
            {predefinedComponents
              .filter(component =>
                component?.name
                  ?.toLowerCase()
                  .includes(searchTerm.toLowerCase())
              )
              .map(component => (
                <ComponentCard component={component} />
              ))}
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '0.5rem',
            }}
          >
            <Typography variant="h6">Preview</Typography>
          </Box>
          <Box
            sx={{
              maxHeight: '650px',
              overflowY: 'auto',
            }}
          >
            {currentComponents.length !== 0 ? (
              <Reorder.Group
                axis="y"
                values={currentComponents}
                onReorder={setDocumentComponents}
                as="div"
              >
                {currentComponents.map(component => (
                  <Reorder.Item key={component.name} value={component} as="div">
                    <TemplateComponentCard component={component} />
                  </Reorder.Item>
                ))}
              </Reorder.Group>
            ) : (
              <NoComponentsMessage />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export const NoComponentsMessage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        marginTop: '1rem',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Typography variant="h5" sx={{ color: colorThemes.GREY_500 }}>
        No components selected
      </Typography>
    </Box>
  );
};

function ComponentCard({ component }: { component: DocumentationEntry }) {
  const setDocumentComponents = useSetRecoilState(documentComponentsAtom);
  const addComponent = () => {
    setDocumentComponents(components => [...components, component]);
  };
  return (
    <Box
      sx={{
        padding: 1,
        width: 'full',
        height: 50,
        transition: 'all .2s',
        border: '2px solid #334E68',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: colorThemes.DARK_BLUE_600,
        },
        marginBottom: 1,
        borderRadius: 1,
      }}
      onClick={addComponent}
    >
      <Typography variant="subtitle1">
        {DocumentComponentNameMap[component.type]}
      </Typography>
    </Box>
  );
}

function TemplateComponentCard({
  component,
}: {
  component: DocumentationEntry;
}) {
  const setDocumentComponents = useSetRecoilState(documentComponentsAtom);
  const removeComponent = () => {
    setDocumentComponents(components =>
      components.filter(c => c.name !== component.name)
    );
  };
  return (
    <Box
      sx={{
        padding: 1,
        width: 'full',
        height: 50,
        transition: 'all .2s',
        border: '2px solid #334E68',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: colorThemes.DARK_BLUE_600,
        },
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 1,
        borderRadius: 1,
      }}
    >
      <Typography variant="subtitle1">
        {DocumentComponentNameMap[component.type]}
      </Typography>
      <IconButton
        sx={{
          padding: 0,
        }}
        onClick={removeComponent}
      >
        <RemoveCircle sx={{ color: colorThemes.GREY_200 }} />
      </IconButton>
    </Box>
  );
}
