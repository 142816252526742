import { Box, Grid, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import {
  DocumentationEntry,
  documentComponentsAtom,
} from '../../DocumentationPageStates';
import { colorThemes } from '../../../../theme';
import DocumentResultView from './DocumentResultView';
import { NoComponentsMessage } from '../components/ComponentsTab';
import { DocumentComponentNameMap } from '../template/DocumentationTemplates';

export default function GenerationTab() {
  const currentComponents = useRecoilValue(documentComponentsAtom);

  return (
    <Box sx={{ width: 'full' }}>
      <Grid container spacing={10}>
        <Grid item xs={3}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '1rem',
            }}
          >
            <Typography variant="h6">Preview</Typography>
          </Box>
          <Box
            sx={{
              maxHeight: '650px',
              overflowY: 'auto',
            }}
          >
            {currentComponents.length !== 0 ? (
              currentComponents.map(component => (
                <ComponentCard component={component} />
              ))
            ) : (
              <NoComponentsMessage />
            )}
          </Box>
        </Grid>
        <Grid item xs={9}>
          <DocumentResultView />
        </Grid>
      </Grid>
    </Box>
  );
}

function ComponentCard({ component }: { component: DocumentationEntry }) {
  return (
    <Box
      sx={{
        padding: 1,
        width: 'full',
        height: 50,
        transition: 'all .2s',
        border: '2px solid #334E68',
        '&:hover': {
          backgroundColor: colorThemes.DARK_BLUE_600,
        },
        marginBottom: 1,
        borderRadius: 1,
      }}
    >
      <Typography variant="subtitle1">
        {DocumentComponentNameMap[component.type]}
      </Typography>
    </Box>
  );
}
