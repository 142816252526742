import React from 'react';
import {
  Typography,
  Switch,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  Tooltip,
} from '@mui/material';
import { Refresh as ResetIcon } from '@mui/icons-material';

interface Framework {
  frameworkName: string;
  isEnabled: boolean;
  templateId: string;
  description: string | null;
}

interface FrameworkListProps {
  title: string;
  frameworks: Framework[];
  loading: boolean;
  onToggle: (id: string, isEnabled: boolean) => void;
  onReset?: (templateId: string) => void;
  showResetButton: boolean;
}

const FrameworkList: React.FC<FrameworkListProps> = ({
  title,
  frameworks,
  loading,
  onToggle,
  onReset,
  showResetButton,
}) => (
  <Box>
    <Typography variant="h6" gutterBottom>
      {title}
    </Typography>
    <List>
      {frameworks.map(
        ({ frameworkName, isEnabled, templateId, description }) => (
          <ListItem
            key={templateId}
            alignItems="flex-start"
            sx={{ flexDirection: 'column', alignItems: 'stretch' }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <ListItemText primary={frameworkName} secondary={description} />
              <Switch
                edge="end"
                onChange={() => onToggle(templateId, !isEnabled)}
                checked={isEnabled}
                disabled={loading}
              />
            </Box>
            {showResetButton && onReset && (
              <Box display="flex" justifyContent="flex-end" mt={1}>
                <Tooltip
                  title={!isEnabled ? 'Enable the framework to reset' : ''}
                >
                  <span>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => onReset(templateId)}
                      disabled={loading || !isEnabled}
                      startIcon={<ResetIcon />}
                    >
                      Reset
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            )}
          </ListItem>
        )
      )}
    </List>
  </Box>
);

export default FrameworkList;
