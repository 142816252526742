import {
  Box,
  Card,
  CardContent,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Typography,
  Button,
} from '@mui/material';
import { useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { colorThemes } from '../theme';
import { Notification } from '../__generated__/gql/graphql';
import { Link } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';

const MARK_ALL_AS_READ_MUTATION = gql`
  mutation MarkAllNotificationsAsRead($userId: ID!) {
    markAllNotificationsAsRead(userId: $userId) {
      user {
        notification {
          id
          name
          description
          isRead
          actionUrl
        }
      }
    }
  }
`;

export default function UserNotifications({
  notifications,
  userId,
}: {
  notifications: Notification[];
  userId: string;
}) {
  const [markAllAsRead] = useMutation(MARK_ALL_AS_READ_MUTATION, {
    variables: { userId },
    onError: error => {
      console.error('Error marking all as read:', error);
    },
  });

  const handleMarkAllAsRead = () => {
    markAllAsRead();
  };

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          gap: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" component="div">
            Notifications
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleMarkAllAsRead}
          >
            Mark All as Read
          </Button>
        </Box>

        <Box
          sx={{
            width: '100%',
            paddingLeft: '8px',
            height: '80vh',
            overflowY: 'auto',
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{ marginBottom: '0.5rem' }}
          >
            {
              notifications?.filter(notification => !notification?.isRead)
                .length
            }{' '}
            Open notifications
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            {notifications
              ?.filter(notification => !notification?.isRead)
              .map(notification => (
                <NotificationCard
                  key={notification?.id}
                  notification={notification}
                />
              ))}
          </Box>
          <Typography
            variant="h6"
            component="div"
            sx={{ marginTop: '1.5rem', marginBottom: '0.5rem' }}
          >
            {notifications?.filter(notification => notification?.isRead).length}{' '}
            Read notifications
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            {notifications
              ?.filter(notification => notification?.isRead)
              .map(notification => (
                <NotificationCard
                  key={notification?.id}
                  notification={notification}
                />
              ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

const NotificationCard = ({ notification }: { notification: Notification }) => {
  const [open, setOpen] = useState(false);

  function handleClick() {
    setOpen(!open);
  }

  return (
    <Box>
      <ListItemButton
        onClick={handleClick}
        sx={{
          backgroundColor: notification.isRead
            ? colorThemes.DARK_BLUE_400
            : colorThemes.DARK_BLUE_200,
          borderRadius: '8px',
          borderBottomLeftRadius: open ? '0px' : '8px',
          borderBottomRightRadius: open ? '0px' : '8px',
          padding: '4px',
          ':hover': {
            backgroundColor: colorThemes.DARK_BLUE_400,
          },
        }}
      >
        <ListItemIcon>
          <Radio
            checked={notification.isRead ?? false}
            disabled={notification.isRead ?? false}
          />
        </ListItemIcon>
        <ListItemText primary={notification.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open}>
        <Box
          sx={{
            backgroundColor: notification.isRead
              ? colorThemes.DARK_BLUE_400
              : colorThemes.DARK_BLUE_200,
            padding: '1.2rem',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
        >
          <Typography variant="subtitle2" sx={{ whiteSpace: 'pre-wrap' }}>
            {notification.description}
          </Typography>
          <Box
            sx={{ display: 'flex', justifyContent: 'end', marginTop: '1rem' }}
          >
            <Button
              variant="outlined"
              sx={{ paddingY: '0.2rem' }}
              component={Link}
              to={notification.actionUrl || '/'}
              endIcon={
                <ArrowForwardIosRoundedIcon
                  fontSize="small"
                  sx={{ color: colorThemes.YELLOW_200 }}
                />
              }
            >
              To the notification
            </Button>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};
