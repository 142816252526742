import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useState } from 'react';
import {
  DocumentationEntry,
  documentComponentsAtom,
  documentTemplateAtom,
  documentTitleAtom,
} from '../../DocumentationPageStates';
import { colorThemes } from '../../../../theme';
import {
  DocumentationTemplate,
  DocumentationTemplates,
  DocumentComponentNameMap,
} from './DocumentationTemplates';
import {
  DataSourceEnum,
  DocumentationScopeEnum,
  DocumentComponentTypeEnum,
} from '../../../../__generated__/gql/graphql';
import { NoComponentsMessage } from '../components/ComponentsTab';

export default function TemplateTab() {
  const currentComponents = useRecoilValue(documentComponentsAtom);
  const currentTemplate = useRecoilValue(documentTemplateAtom);
  const [searchTerm, setSearchTerm] = useState<string>('');
  return (
    <Box sx={{ width: 'full' }}>
      <Grid container spacing={10}>
        <Grid item xs={5}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '0.5rem',
            }}
          >
            <Typography variant="h6">Templates</Typography>
            <TextField
              id="search"
              sx={{ width: 200, height: 40 }}
              size="small"
              onChange={e => setSearchTerm(e.target.value)}
              placeholder="Search Template"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              maxHeight: '650px',
              overflowY: 'auto',
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ marginTop: '0.5rem' }}>
                  Organizational Wide Templates
                </Typography>
                <Divider sx={{ marginTop: '0.5rem' }} />
              </Grid>
              {DocumentationTemplates.filter(template =>
                template.title.toLowerCase().includes(searchTerm.toLowerCase())
              )
                .filter(
                  template =>
                    template.documentationScope ===
                    DocumentationScopeEnum.Organization
                )
                .map(template => (
                  <Grid item xs={6}>
                    <TemplateCard template={template} />
                  </Grid>
                ))}
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ marginTop: '0.5rem' }}>
                  Project Wide Templates
                </Typography>
                <Divider sx={{ marginTop: '0.5rem' }} />
              </Grid>
              {DocumentationTemplates.filter(template =>
                template.title.toLowerCase().includes(searchTerm.toLowerCase())
              )
                .filter(
                  template =>
                    template.documentationScope ===
                    DocumentationScopeEnum.Project
                )
                .map(template => (
                  <Grid item xs={6}>
                    <TemplateCard template={template} />
                  </Grid>
                ))}
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ marginTop: '0.5rem' }}>
                  Experiment Wide Templates
                </Typography>
                <Divider sx={{ marginTop: '0.5rem' }} />
              </Grid>
              {DocumentationTemplates.filter(template =>
                template.title.toLowerCase().includes(searchTerm.toLowerCase())
              )
                .filter(
                  template =>
                    template.documentationScope ===
                    DocumentationScopeEnum.Experiment
                )
                .map(template => (
                  <Grid item xs={6}>
                    <TemplateCard template={template} />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '0.5rem',
            }}
          >
            <Typography variant="h6">Preview</Typography>
          </Box>
          <Box
            sx={{
              maxHeight: '650px',
              overflowY: 'auto',
            }}
          >
            <Typography sx={{ marginBottom: '1rem' }}>
              {currentTemplate
                ? currentTemplate.description
                : 'No Template Selected'}
            </Typography>
            {currentComponents.length !== 0 ? (
              currentComponents.map(component => (
                <PreviewComponentCard component={component} />
              ))
            ) : (
              <NoComponentsMessage />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

function TemplateCard({ template }: { template: DocumentationTemplate }) {
  const setDocumentComponents = useSetRecoilState(documentComponentsAtom);
  const setDocumentTitle = useSetRecoilState(documentTitleAtom);
  const [selectedTemplate, setDocumentTemplate] =
    useRecoilState(documentTemplateAtom);
  const selectTemplate = () => {
    setDocumentComponents(
      template.components.map(component => ({
        id: '',
        type:
          component.componentType || DocumentComponentTypeEnum.LegalDisclaimer,
        name: component.name || '',
        query: component.query || '',
        args: component.args || [],
        dataSource: component.dataSource || DataSourceEnum.Text,
      }))
    );
    setDocumentTemplate(template);
    setDocumentTitle(template.title);
  };

  return (
    <Box
      sx={{
        padding: 1,
        width: 'full',
        height: 100,
        transition: 'all .2s',
        border: '2px solid #334E68',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: colorThemes.DARK_BLUE_600,
        },
        backgroundColor:
          selectedTemplate === template
            ? colorThemes.DARK_BLUE_600
            : colorThemes.DARK_BLUE_800,
        borderRadius: 1,
      }}
      onClick={selectTemplate}
    >
      <Typography variant="subtitle1">{template.title}</Typography>
    </Box>
  );
}

function PreviewComponentCard({
  component,
}: {
  component: DocumentationEntry;
}) {
  return (
    <Box
      sx={{
        padding: 1,
        width: 'full',
        height: 50,
        transition: 'all .2s',
        border: '2px solid #334E68',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: colorThemes.DARK_BLUE_600,
        },
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 1,
        borderRadius: 1,
      }}
    >
      <Typography variant="subtitle1">
        {DocumentComponentNameMap[component.type]}
      </Typography>
    </Box>
  );
}
